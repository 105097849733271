<template>
  <v-container class="contentContainer">
    <!-- PAGE BANNER - Course Title -->
    <PageHeader class="pb-4" heading="Course Tree" fullscreen>
      <v-dialog v-model="showDialog" max-width="500px">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="accent" v-bind="attrs" v-on="on" size="21">mdi-information</v-icon>
        </template>
        <v-card :outlined="store.app.darkMode" style="border-radius: 5px">
          <v-card-title class="pt-6 pb-4 text-h5 font-weight-bold accent--text">Course Tree</v-card-title>
          <v-card-text class="pb-0">
            <p class="text--secondary mb-1">The below tree is an interactive visualization of:</p>
            <ul class="text--secondary mt-0 mb-4">
              <li class="text--secondary">
                <strong>Prerequisites:</strong> Courses required to take a particular course
              </li>
              <li class="text--secondary">
                <strong>Post-requisites:</strong> Courses that a particular course is used as a prerequisite for
                (i.e. courses that a given course "unlocks")
              </li>
            </ul>
            <p class="text--secondary"><strong>Note: </strong>The post-requisite tree only shows the
              post-requisites one level at a time. If users want to view the post-requisite tree for a particular
              course on the current post-requisite tree, click on the course node and watch the tree grow!</p>
            <p class="text--secondary">Duplicate courses are greyed out to better show what courses
              are actually needed. Additionally, the duplicate courses have their trees auto collapsed hopefully giving
              users a better overview of the entire tree structure.</p>
            <v-alert class="my-4" border="left" text type="warning" icon="mdi-alert-outline">
              <span class="mb-0 text-body-1">By default the tree does not show whether all or some combination of
                courses are required. Enable <strong>Prerequisite Nodes</strong> to view more info.</span>
            </v-alert>
          </v-card-text>
          <v-card-actions class="pt-0 pb-4">
            <v-spacer/>
            <v-btn color="accent" text @click="showDialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </PageHeader>
    <!-- MAIN CONTENT AREA - Content Section -->
    <v-row justify="center" class="mt-0">
      <v-col class="pt-0">
        <!-- COURSE SELECTOR - Autocomplete Section -->
        <v-row class="pb-2 my-0" justify="space-between">
          <v-col class="col-xl-6 col-md-7 col-sm-12 pa-0 mr-xl-8 searchColumn align-center" cols="12">
            <v-autocomplete solo-inverted background-color="border" clearable hide-no-data :items="store.data.courseList"
                            height="50" label="Select a course" v-model="selectedCourse" hide-details flat
                            prepend-inner-icon="mdi-school-outline" open-on-clear class="autoComplete" ref="selectBar" color="accent">
              <template v-slot:append-outer>
                <v-divider vertical class="mx-0"/>
                <v-menu open-on-hover close-on-click bottom offset-y right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed text v-bind="attrs" v-on="on" class="font-weight-medium text-capitalize mx-3 px-2 px-md-3">
                      {{ store.app.onDesktop ? courseTree.options[courseTree.active].label : 'Type' }}
                      <v-icon class="ml-1 ml-md-3 mr-0" color="secondary">mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(type, idx) in courseTree.options" :key="idx"
                                 @click="$router.push({ path: $route.fullPath, query: { v: idx.toString() } })">
                      <v-list-item-title>{{ type.label }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" class="col-lg-4 col-md-5 col-sm-12" align-self="center">
            <v-row v-if="courseTree.tree.length" align="center" justify="end">
              <v-btn text depressed class="px-2 text-capitalize" @click="downloadTree(courseTree.code)">
                <v-icon color="accent" class="mr-2">mdi-download</v-icon>
                Download
              </v-btn>
              <ShareButton :value="courseTree.code" full-link/>
            </v-row>
          </v-col>
        </v-row>
        <!-- MAIN CONTENT AREA - Prerequisite tree and course info section -->
        <v-row v-show="courseTree.tree.length" class="pa-0 courseTree">
          <v-col cols="12" class="col-xl-3 pl-0 pt-3 pt-md-6 pb-6 pb-sm-10 pb-xl-0 ma-0 d-flex flex-column">
            <span v-if="courseTree.tree.length">
              <v-row  class="px-3 pb-0 align-center">
                <v-col cols="2" class="pa-0" :style="!store.app.onMobile ? 'min-width: 185px' : ''">
                  <p class="text-h5 text-sm-h4 mb-0 itemHover mr-3" @click="$router.push({ path: '/courses', query: { c: courseDetails.code } })">
                  {{ courseDetails.code }}
                </p>
                </v-col>
                <v-spacer v-if="store.app.onMobile"/>
                <QuickStats :stats="courseDetails"/>
              </v-row>
              <InfoDisplay :course-info="courseInfo" :show-tree="false" :mobile="$vuetify.breakpoint.xlOnly" :show-semesters="true" class="mt-3 pa-3"/>
            </span>
          </v-col>
          <v-col cols="12" class="col-xl-9 treeCol" :style="gridSize" ref="treeContainer">
            <v-row justify="end" class="align-center pl-4 pr-2 py-6 py-md-4 ma-0 stickyTreeControls">
              <v-card class="pa-3" style="border-radius: 6px" elevation="1">
                <v-row class="ma-0 align-center">
                  <v-spacer/>
                  <span v-if="store.app.onDesktop" class="d-flex flex-row align-center">
                    <v-switch v-model="prereqNodes" :loading="loading" class="mt-0" inset dense hide-details label="Prerequisite Nodes" color="accent"/>
                  </span>
                  <v-divider v-if="store.app.onDesktop" vertical class="mx-5"/>
                  <p class="zoomText text--secondary mb-0 mr-1">Zoom:</p>
                  <v-icon :disabled="treeScale <= 0.6" size="22" color="accent" class="mx-3 zoomBtn"
                          @click="treeScale -= 0.1">mdi-minus-circle-outline</v-icon>
                  <v-tooltip bottom color="tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <p class="text-center zoomText text--secondary itemHover mb-0" v-bind="attrs" v-on="on" style="width: 45px;"
                         @click="treeScale = 0.8">{{ Math.floor((treeScale + 0.2)*100) }}%</p>
                    </template>
                    <span class="font-weight-medium">Reset</span>
                  </v-tooltip>
                  <v-icon :disabled="treeScale >= 1" size="22" color="accent" class="mx-3 zoomBtn"
                          @click="treeScale += 0.1">mdi-plus-circle-outline</v-icon>
                  <v-divider vertical/>
                  <v-tooltip bottom color="tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="accent" v-bind="attrs" v-on="on" @click="fullscreen = !fullscreen"
                              class="ml-3">mdi-arrow-expand-all</v-icon>
                    </template>
                    <span class="font-weight-medium">Fullscreen</span>
                  </v-tooltip>
                </v-row>
              </v-card>
            </v-row>
            <span class="prereqTree" style="width: max-content; display: inline-block">
              <vue-okr-tree :data="courseTree.tree" node-key="id" :default-expanded-keys="courseTree.defaultExpanded"
                            ref="tree" :render-content="renderContent" :style="treeZoom" show-node-num show-collapsable
                            direction="horizontal" @node-click="handleNodeClick"/>
            </span>
          </v-col>
        </v-row>
        <!-- DEFAULT PAGE - Feature icon and description -->
        <v-row v-if="courseTree.tree.length < 1" style="margin-top: 10%">
          <v-col>
            <v-row justify="center"><v-icon color="accent" class="treeIcon py-6">mdi-sitemap</v-icon></v-row>
            <p class="text-h5 text-md-h4 text-lg-h4 text-center">Select a course to get started!</p>
            <p class="text--secondary text-body-1 text-center">
              Quickly check all prerequisite courses that are required to take upper year courses
            </p>
          </v-col>
        </v-row>
        <!-- FULLSCREEN DIALOG - Fullscreen course tree section -->
        <v-dialog v-model="fullscreen" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card v-if="courseTree.tree.length" color="background" :style="gridSize">
            <v-toolbar color="#002D63">
              <v-btn icon dark @click="fullscreen = !fullscreen"><v-icon>mdi-close</v-icon></v-btn>
              <v-toolbar-title class="white--text font-weight-medium">{{ courseTree.code }} - Prerequisite Tree</v-toolbar-title>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="#FFFFFF" class="pa-2 ml-4" @click="downloadTree(courseTree.code)">mdi-download</v-icon>
                </template>
                <span>Download</span>
              </v-tooltip>
              <v-spacer/>
              <p class="zoomText mb-0 mr-3 white--text font-weight-medium">Zoom:</p>
              <v-icon :disabled="treeScale <= 0.6" size="22" color="#FFFFFF" class="mx-3 zoomBtn"
                      @click="treeScale -= 0.1">mdi-minus-circle-outline</v-icon>
              <v-tooltip bottom color="tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <p class="text-center zoomText white--text font-weight-medium itemHover mb-0" v-bind="attrs" v-on="on"
                     style="width: 45px;" @click="treeScale = 0.8">{{ Math.floor((treeScale + 0.2)*100) }}%</p>
                </template>
                <span class="font-weight-medium">Reset</span>
              </v-tooltip>
              <v-icon :disabled="treeScale >= 1" size="22" color="#FFFFFF" class="mx-3 zoomBtn"
                      @click="treeScale += 0.1">mdi-plus-circle-outline</v-icon>
            </v-toolbar>
            <v-row justify="center" class="fullScreen pa-0 mt-0">
              <v-col cols="12" class="overflow-auto" ref="treeContainerFS" style="height: calc(100vh - 64px)">
                <vue-okr-tree :data="courseTree.tree" node-key="id" :default-expanded-keys="courseTree.defaultExpanded"
                              :render-content="renderContent" :style="treeZoom" show-node-num show-collapsable
                              direction="horizontal" @node-click="handleNodeClick"/>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useAllStores } from '@/stores/useAllStores'
import ShareButton from '@/components/ShareButton'
import InfoDisplay from '@/components/InfoDisplay'
import QuickStats from '@/components/QuickStats'
import PageHeader from '@/components/PageHeader'
import html2canvas from 'html2canvas'

import { VueOkrTree } from 'vue-okr-tree'
import 'vue-okr-tree/dist/vue-okr-tree.css'
import { saveAs } from 'file-saver'
import { deepClone } from '@/utils/shared/helpers'

export default {
  name: 'CourseTree',
  components: { PageHeader, ShareButton, InfoDisplay, QuickStats, VueOkrTree },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    loading: false,
    enableNodeToggle: true,
    treePosition: { top: 0, left: 0, x: 0, y: 0 },
    courseDetails: { course: '' },
    selectedCourse: null,
    courseTree: {
      code: '',
      active: 0,
      options: [
        { label: 'Prerequisites', value: 'prereq' },
        { label: 'Post-requisites', value: 'postreq' }
      ],
      tree: [],
      defaultExpanded: [],
      defaultNodes: [],
      prereqNodes: []
    },
    treeScale: 0.8,
    fullscreen: false,
    showDialog: false,
    mandatoryFields: ['code', 'bird', 'drop', 'rating', 'prereq', 'exclusion', 'numReviews'],
    prereqNodes: false
  }),
  mounted () {
    document.addEventListener('mousedown', this.mouseDownHandler)
  },
  beforeDestroy () {
    document.removeEventListener('mousedown', this.mouseDownHandler)
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.store.errors.graphQL) {
        if (vm.store.user.userInfo) vm.store.data.loadUserCourses()
        if (to.query.v) vm.courseTree.active = to.query.v
        if (to.query.c) vm.getData(to.query.c)
      } else {
        vm.$toast.error('An error occurred when contacting the server. Please try again later.')
        vm.store.errors.graphQL = null
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    if (!['/professors', '/prereqs', '/directory'].includes(to.path) && this.store.data.filterState.filters) {
      this.store.data.resetSelectedFilters()
    }
    next()
  },
  watch: {
    selectedCourse: function (course) {
      if (course) {
        this.$router.push({ path: this.$route.fullPath, query: { c: course.slice(0, 8), v: this.courseTree.active.toString() } })
      } else {
        if (course) this.$toast.warning('"' + course + '"' + ' is not a valid course!')
      }
    },
    '$route.query': function (newValue) {
      this.courseTree.tree = []
      this.courseTree.defaultNodes = []
      if (newValue.v) this.courseTree.active = newValue.v
      if (newValue.c && newValue.v) {
        this.getData(this.$route.query.c)
      } else {
        this.selectedCourse = null
      }
    },
    prereqNodes: function (newValue) {
      if (this.enableNodeToggle) this.togglePrereqNode(newValue)
    }
  },
  computed: {
    treeZoom () {
      return { '--tree-scale': this.treeScale }
    },
    courseInfo () {
      let courseInfo = this.courseDetails
      if (this.courseDetails) {
        if (this.store.app.onMobile) {
          courseInfo = Object.keys(this.courseDetails)
            .filter(key => this.mandatoryFields.includes(key))
            .reduce((obj, key) => {
              obj[key] = this.courseDetails[key]
              return obj
            }, {})
        }
      }
      return courseInfo
    },
    gridSize () {
      const factor = ((this.treeScale + 0.2) * 100) + 'px '
      const background = 'background: conic-gradient(from 90deg at 1px 1px,#0000 90deg,var(--v-border-base) 0) 0 0/'
      const height = this.$vuetify.breakpoint.xlOnly ? 'height: 82vh; ' : 'max-height: 88.5vh; border: 1px solid var(--v-border-base); '
      return (height + background + factor + factor)
    }
  },
  methods: {
    renderContent (h, node) {
      return (
        <div class="diy-wrapper px-3 py-1" style={node.data.taken ? 'border-left: 5px solid var(--v-success-base)'
          : ((node.data.display) ? 'border-left: 5px solid var(--v-accent-base)' : 'border-left: 5px solid #828282; opacity: 0.3')}>
          <div style="min-width: 225px; font-weight: bold">{node.data.label}
            { node.data.taken &&
              <i class="ml-1 mdi mdi-check-decagram success--text"/>
            }
          </div>
          <div class="text-break">{node.data.content}</div>
          { node.data.drop !== null &&
            <div class="row justify-center pt-5 pb-4">
              <div class="font-weight-medium mr-1">Drop Rate:</div>{node.data.drop}%
            </div>
          }
        </div>
      )
    },
    async getData (course) {
      this.enableNodeToggle = false
      if (course !== this.courseDetails.code) await this.getDetails(course)
      if (this.courseTree.active < 1) {
        await this.getPrereqTree(course)
      } else {
        await this.getCoursePostreqs(course)
      }
      this.prereqNodes = false
      this.courseTree.code = course
      this.enableNodeToggle = true
      // Reset scroll position
      this.$refs.treeContainer.scrollLeft = 0
    },
    async handleNodeClick (data) {
      if (parseInt(this.courseTree.active)) await this.getCoursePostreqs(data.label)
    },
    async getPrereqTree (course) {
      const q = {
        query: 'query getCourseTree($course: String!) { getCourseTree(course: $course) { courseTree } }',
        variables: { course: course },
        operationName: 'getCourseTree'
      }
      try {
        const response = await fetch('/graphql', {
          method: 'post',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify(q)
        })
        const graphQlRes = await response.json()
        if (graphQlRes.data) {
          const tree = []
          if (graphQlRes.data.getCourseTree.courseTree.length === 0) return
          if (this.store.user.userInfo) {
            tree.push(this.addCheckMarks(graphQlRes.data.getCourseTree.courseTree[0].tree[1]))
          } else {
            tree.push(graphQlRes.data.getCourseTree.courseTree[0].tree[1])
          }
          this.courseTree.defaultExpanded = graphQlRes.data.getCourseTree.courseTree[0].tree[0]
          this.courseTree.defaultNodes = tree
          this.courseTree.tree = tree
          this.$refs.selectBar.blur()
          // GTAG
          this.$gtag.event('tree_' + course, { value: 1 })
        } else {
          this.$toast.error(graphQlRes.errors[0].message)
        }
      } catch (error) {
        this.$toast.error('An error occurred when contacting the server. Please try again later.')
      }
    },
    async getDetails (course) {
      // Call backend for course details
      const q = {
        query: 'query courseInfo($course: String!) { courseInfo(course: $course) { ' +
          'code, course, description, bird, drop, rating, prereq, exclusion, semesters, numReviews } }',
        variables: { course: course },
        operationName: 'courseInfo'
      }
      try {
        const response = await fetch('/graphql', {
          method: 'post',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify(q)
        })
        const graphQlRes = await response.json()
        if (graphQlRes.data) {
          if (graphQlRes.data.courseInfo.length === 0) {
            await this.$router.replace({ query: null })
            this.$toast.warning('Sorry! We could not find a course matching: ' + course)
          } else {
            this.courseDetails = graphQlRes.data.courseInfo[0]
            if (!this.selectedCourse) this.selectedCourse = this.courseDetails.course
          }
        } else {
          this.$toast.error(graphQlRes.errors[0].message)
        }
      } catch (error) {
        this.$toast.error('An error occurred when contacting the server. Please try again later.')
      }
    },
    getPrereqMap (courses) {
      return new Promise((resolve, reject) => {
        // Call backend for course details
        const q = {
          query: 'query getCoursePrereqs($courses: [String!]) { getCoursePrereqs(courses: $courses) }',
          variables: { courses: courses },
          operationName: 'getCoursePrereqs'
        }
        fetch('/graphql', {
          method: 'post',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify(q)
        }).then((response) => response.json())
          .then((graphQlRes) => {
            if (graphQlRes.data) {
              resolve(graphQlRes.data.getCoursePrereqs)
            } else {
              reject(graphQlRes.errors[0].message)
            }
          })
          .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
      })
    },
    async getCoursePostreqs (course) {
      // Call backend for course details
      const q = {
        query: 'query getCoursePostreqs($course: String!) { getCoursePostreqs(course: $course) }',
        variables: { course: course },
        operationName: 'getCoursePostreqs'
      }
      try {
        const response = await fetch('/graphql', {
          method: 'post',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify(q)
        })
        const graphQlRes = await response.json()
        if (graphQlRes.data) {
          const root = this.courseTree.defaultNodes.length ? this.courseTree.defaultNodes[0] : graphQlRes.data.getCoursePostreqs
          this.courseTree.defaultExpanded = this.courseTree.defaultExpanded
            .concat(graphQlRes.data.getCoursePostreqs.children.map(course => {
              return course.label
            }))
          const postReqTree = []
          if (this.store.user.userInfo) {
            postReqTree.push(this.addCheckMarks(this.buildPostreqTree(root, course, graphQlRes.data.getCoursePostreqs.children)))
          } else {
            postReqTree.push(this.buildPostreqTree(root, course, graphQlRes.data.getCoursePostreqs.children))
          }
          this.courseTree.tree = postReqTree
          this.courseTree.defaultNodes = postReqTree
          if (graphQlRes.data.getCoursePostreqs.children.length < 1) {
            this.$toast.warning('Could not find any courses requiring: ' + course)
          }
          if (this.prereqNodes) await this.togglePrereqNode(true)
        } else {
          this.$toast.error(graphQlRes.errors[0].message)
        }
      } catch (error) {
        this.$toast.error('An error occurred when contacting the server. Please try again later.')
      }
    },
    async togglePrereqNode (showPrereqNodes) {
      const treeKey = showPrereqNodes ? 'prereqNodes' : 'defaultNodes'
      if ((this.courseTree.active < 1 && this.courseTree[treeKey].length && this.courseTree.code === this.courseTree[treeKey][0].label) ||
        (treeKey === 'defaultNodes' && this.courseTree.code === this.courseTree[treeKey][0].label)) {
        this.courseTree.tree = this.courseTree[treeKey]
      } else {
        this.loading = true
        const courses = new Set(JSON.stringify(this.courseTree.tree).match(/[A-Z]{3}([A-Z]|[0-4])\d{2}[A-Z]\d/g))
        const copyOfTree = deepClone(this.courseTree.tree[0])
        this.getPrereqMap(Array.from(courses)).then(prereqMap => {
          this.courseTree.prereqNodes = [this.rebuildPrereqTree(copyOfTree, prereqMap)]
          this.courseTree.tree = this.courseTree.prereqNodes
          this.loading = false
        }).catch(message => this.$toast.error(message))
      }
      this.$gtag.event('course_tree_' + treeKey, { value: 1 })
    },
    rebuildPrereqTree (tree, courseMap) {
      if (!('children' in tree)) {
        tree.content = courseMap[tree.label] || 'No prerequisites'
        tree.drop = null
      } else {
        tree.content = courseMap[tree.label] || 'No prerequisites'
        tree.drop = null
        tree.children.forEach(child => {
          this.rebuildPrereqTree(child, courseMap)
        })
      }
      return tree
    },
    buildPostreqTree (tree, target, children) {
      if (tree.label === target) {
        tree.children = children
      } else {
        tree.children.forEach((course) => { this.buildPostreqTree(course, target, children) })
      }
      return tree
    },
    addCheckMarks (tree) {
      if (!('children' in tree)) {
        tree.taken = this.checkIfTaken(tree.label) > -1
      } else {
        tree.taken = this.checkIfTaken(tree.label) > -1
        tree.children.forEach((course) => {
          this.addCheckMarks(course)
        })
      }
      return tree
    },
    checkIfTaken (code) {
      return this.store.user.userInfo.data.courses.findIndex((course) => { return course.code === code })
    },
    downloadTree (course) {
      const prereqTree = document.querySelector('.prereqTree')
      const nodes = prereqTree.getElementsByClassName('org-chart-node-label-inner')
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].style.background = 'transparent'
      }
      const options = {
        logging: false,
        scale: 2,
        backgroundColor: this.store.app.darkMode ? '#0F0F0F' : '#FFFFFF'
      }
      html2canvas(prereqTree, options).then(canvas => {
        canvas.toBlob(function (blob) {
          saveAs(blob, course + '-Prerequisites.jpeg')
        }, 'image/jpeg')
      })
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].style.background = 'var(--v-background-base)'
      }
      this.$gtag.event('download_tree_' + course, { value: 1 })
    },
    mouseDownHandler (e) {
      document.addEventListener('mousemove', this.mouseMoveHandler)
      document.addEventListener('mouseup', this.mouseUpHandler)
      const container = this.fullscreen ? 'treeContainerFS' : 'treeContainer'
      this.$refs[container].style.cursor = 'grabbing'
      this.treePosition = {
        // The current scroll
        left: this.$refs[container].scrollLeft,
        top: this.$refs[container].scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY
      }
    },
    mouseMoveHandler (e) {
      const container = this.fullscreen ? 'treeContainerFS' : 'treeContainer'
      // How far the mouse has been moved
      const dx = e.clientX - this.treePosition.x
      const dy = e.clientY - this.treePosition.y
      // Scroll the element
      this.$refs[container].scrollTop = this.treePosition.top - dy
      this.$refs[container].scrollLeft = this.treePosition.left - dx
    },
    mouseUpHandler (e) {
      document.removeEventListener('mousemove', this.mouseMoveHandler)
      document.removeEventListener('mouseup', this.mouseUpHandler)
      this.$refs[this.fullscreen ? 'treeContainerFS' : 'treeContainer'].style.cursor = 'grab'
    }
  }
}
</script>

<style scoped>
  >>>.theme--light.v-list-item .v-list-item__mask {
    color: #0789F2;
    background: #eee;
  }
  >>>.theme--dark.v-list-item .v-list-item__mask {
    color: #0789F2;
    background: #000000;
  }
  >>>.v-input__icon {
    margin-right: 6px;
  }
  .org-chart-container {
    transform: scale(var(--tree-scale));
  }
  >>>.org-chart-node-label-inner {
    max-width: 500px !important;
    background: var(--v-background-base);
  }
  >>>.org-chart-node-label-inner {
    border: 2px solid #F0F0F0;
    border-radius: 2px;
  }
  >>>.v-input__append-outer {
    margin: 0 !important;
  }
  .fullScreen {
    min-width: 100%;
    overflow: hidden;
  }
  .searchColumn {
    background: var(--v-border-base);
  }
  .courseTree {
    resize: none;
    overflow: auto;
  }
  .itemHover:hover {
    cursor: pointer;
    color: #0789F2 !important;
  }
  .autoComplete {
    z-index: 10 !important;
    align-items: center;
  }
  .treeCol {
    padding: 0;
    overflow-y: auto;
    position: relative;
    border-radius: 5px;
    border-bottom: 1px solid var(--v-border-base);
    background-attachment: local !important;
  }
  .treeIcon {
    font-size: 75px;
    text-align: center;
  }
  .stickyTreeControls {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .zoomText {
    font-size: 16px;
  }
  .zoomBtn:focus::after {
    opacity: 0 !important;
  }

</style>
